import React, {
	FC,
	ReactElement,
	ReactNode,
	ElementType,
	useCallback,
	useState,
	useRef,
	useEffect,
} from "react";
import classNames from "classnames";
import { useInViewport } from "react-in-viewport";

import { EditorFeatureProps } from "../EditorFeature/EditorFeature";
import { Appear } from "../../Appear/Appear";

import styles from "./EditorSection.module.scss";
import { Button } from "../../Button/Button";

export interface EditorSectionProps {
	heading: ReactNode;
	lead: ReactNode;
	children: ReactElement<EditorFeatureProps>[];
	textRight?: boolean;
	icon: ElementType;
}

export const EditorSection: FC<EditorSectionProps> = ({
	heading,
	lead,
	children,
	textRight = false,
	icon: Icon,
}) => {
	const [currentIndex, setCurrentIndex] = useState<null | number>(null),
		sectionRef = useRef<HTMLElement>(null),
		onVideoEnded = useCallback(() => {
			setCurrentIndex((i) => {
				if (i !== null) return i >= children.length - 1 ? 0 : i + 1;
				return null;
			});
		}, []),
		{ inViewport } = useInViewport(
			sectionRef,
			{ threshold: 0.666 },
			{ disconnectOnLeave: false },
			{}
		),
		onSelect = useCallback(
			(id: string) => {
				const newIndex = children.findIndex((c) => c.props.id === id);
				setCurrentIndex(newIndex);

				if (!inViewport) sectionRef.current?.scrollIntoView();
			},
			[inViewport]
		);

	useEffect(() => {
		// Only kick off the auto videos if we don't have a preference for reduced motion
		if (!matchMedia("(prefers-reduced-motion)").matches) setCurrentIndex(0);
	}, []);

	return (
		<section
			data-viewport={inViewport}
			ref={sectionRef}
			className={classNames(styles.wrapper, { [styles.textRight]: textRight })}
		>
			<div>
				<Icon aria-hidden="true" focusable="false" className={styles.icon} />
				<Appear
					fromLeft={!textRight}
					fromRight={textRight}
					elementType="h3"
					className={styles.heading}
				>
					{heading}
				</Appear>
				<Appear
					fromLeft={!textRight}
					fromRight={textRight}
					elementType="p"
					className={styles.para}
				>
					{lead}
				</Appear>
				<div className={styles.listWrapper}>
					<ul className={styles.list}>
						{/* Clone children so we can pass down additional props like text right or video state */}
						{React.Children.map(children, (child, index) => (
							<li key={index}>
								{React.isValidElement(child)
									? React.cloneElement(child, {
											textRight,
											isCurrent: currentIndex === index,
											onVideoEnded,
											onSelect,
											isPaused: !inViewport,
									  })
									: child}
							</li>
						))}
					</ul>
				</div>
				<Appear
					fromLeft={!textRight}
					fromRight={textRight}
					className={styles.cta}
				>
					<Button to="https://my.adeptly.ai/" variant="small">
						Start building
					</Button>
				</Appear>
			</div>
		</section>
	);
};
