import React, { FC, ReactNode, ElementType } from "react";
import { PageSection } from "../PageSection/PageSection";

import MobileIcon from "../../assets/features/mobile.svg";
import BookIcon from "../../assets/features/book.svg";
import NodesIcon from "../../assets/features/nodes.svg";
import GlobeIcon from "../../assets/features/globe.svg";
import BrainIcon from "../../assets/features/brain.svg";
import AtomIcon from "../../assets/features/atom.svg";

import styles from "./FeatureGrid.module.scss";
import { Appear } from "../Appear/Appear";

interface FeatureProps {
	icon: ElementType;
	id: string;
	heading: ReactNode;
	text: ReactNode;
}

const Feature: FC<FeatureProps> = ({
	icon: Icon,
	id,
	heading,
	text,
}: FeatureProps) => (
	<Appear elementType="li" aria-labelledby={id} className={styles.feature}>
		<Icon className={styles.icon} focusable="false" aria-hidden={true} />
		<h3 id={id} className={styles.heading}>
			{heading}
		</h3>
		<p className={styles.text}>{text}</p>
	</Appear>
);

export const FeatureGrid: FC = () => (
	<PageSection id="power-of-adeptly" heading="The power of&nbsp;Adeptly">
		<ul
			className={styles.list}
			aria-label="Features that show the power of adeptly"
		>
			<Feature
				id="mobile"
				icon={MobileIcon}
				heading="Play on any device"
				text="No installation required. Play anywhere, on any device straight from your&nbsp;browser"
			/>
			<Feature
				id="languages"
				icon={BookIcon}
				heading="Support multiple languages"
				text="Easily translate your game into multiple languages and share your game with the&nbsp;world"
			/>
			<Feature
				id="lms-integration"
				icon={NodesIcon}
				heading="Integrate with your LMS"
				text="Connect your game to your LMS then track completition rates and&nbsp;more"
			/>
			<Feature
				id="add-videos"
				icon={GlobeIcon}
				heading="No hosting required"
				text="When your game is ready simply press “Publish” then share the link to your&nbsp; game"
			/>
			<Feature
				id="adaptive-learning"
				icon={BrainIcon}
				heading="Harness adaptive learning"
				text="Players choose their own path through your game, learning from their decisions as they&nbsp;play"
			/>
			<Feature
				id="real-time-metrics"
				icon={AtomIcon}
				heading="A/B test scenarios"
				text="Use Adeptly to simulate and test different scenarios with your customers/learners/team/students"
			/>
		</ul>
	</PageSection>
);
