import React, { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";

import { PageSection } from "../PageSection/PageSection";

import { EditorSection } from "./EditorSection/EditorSection";
import { EditorFeature } from "./EditorFeature/EditorFeature";

import PencilIcon from "../../assets/platform/pencil.svg";
import WandIcon from "../../assets/platform/wand.svg";
import TestTubeIcon from "../../assets/platform/test-tube.svg";

export const Editor: FC = () => {
	return (
		<PageSection
			id="platform"
			heading="Map your narrative into a game, then track the decisions your players make in&nbsp;realtime"
			headingType="impact"
		>
			<EditorSection
				icon={PencilIcon}
				heading="Simulate any narrative"
				lead="Whether it’s for training or marketing, get your story across in an engaging game players will&nbsp;remember."
			>
				<EditorFeature
					id="rapidly-build"
					text="Rapidly build role playing&nbsp;games"
					videoPath="/videos/game.m4v"
				/>
				<EditorFeature
					id="style-bran"
					text="Style and brand your game how you&nbsp;want"
					videoPath="/videos/styling.m4v"
				/>
				<EditorFeature
					id="currencies"
					text="Create in-game currencies"
					videoPath="/videos/currencies.m4v"
				/>
				<EditorFeature
					id="player-leaderboards"
					text="Add player leaderboards"
					videoPath="/videos/game1.mp4"
				/>
			</EditorSection>
			<EditorSection
				icon={WandIcon}
				textRight={true}
				heading="Easy to build"
				lead="Rapdily map your game decision tree using our easy to use online game&nbsp;builder."
			>
				<EditorFeature
					id="decision-tree"
					text="Map out your decision&nbsp;tree"
					videoPath="/videos/tree.m4v"
				/>
				<EditorFeature
					id="images-videos"
					text="Add images and&nbsp;videos"
					videoPath="/videos/images.m4v"
				/>
				<EditorFeature
					id="conditional-logic"
					text="Add conditional logic"
					videoPath="/videos/logic.m4v"
				/>
				<EditorFeature
					id="publish-share"
					text="Publish and share your&nbsp;game"
					videoPath="/videos/share.m4v"
				/>
			</EditorSection>
			<EditorSection
				icon={TestTubeIcon}
				heading="Collect deep data"
				lead="Track player decision making, spot potential risks and measure behaviour change in&nbsp;real-time."
			>
				<EditorFeature
					id="track-decisions"
					text="Track player decision making in&nbsp;real-time"
					videoPath="/videos/data-summary.m4v"
				/>
				<EditorFeature
					id="spot-bad-decisions"
					text="See where bad decisions are made"
					videoPath="/videos/bad-decisions.m4v"
				/>
				<EditorFeature
					id="behaviour-change"
					text="Measure behaviour change"
					videoPath="/videos/behaviour-change.m4v"
				/>
				<EditorFeature
					id="export-share"
					text="Easily export and share your&nbsp;data"
					videoPath="/videos/export-data.m4v"
				/>
			</EditorSection>
		</PageSection>
	);
};
