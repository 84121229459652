import React, {
	FC,
	ReactNode,
	useCallback,
	useEffect,
	useRef,
	MouseEvent,
	useState,
} from "react";
import classNames from "classnames";
import type Plyr from "plyr";
import "plyr/src/sass/plyr.scss";

import ChevronRightIcon from "../../../assets/heroicons/chevron-right.svg";

import styles from "./EditorFeature.module.scss";
import { Appear } from "../../Appear/Appear";

export interface EditorFeatureProps {
	id: string;
	text: ReactNode;
	textRight?: boolean;
	image?: ReactNode;
	videoPath: string;
	isCurrent?: boolean;
	isPaused?: boolean;
	onVideoEnded?: () => void;
	onSelect?: (id: string) => void;
}

export const EditorFeature: FC<EditorFeatureProps> = ({
	id,
	text,
	textRight = false,
	image,
	videoPath,
	isCurrent = false,
	isPaused = true,
	onVideoEnded,
	onSelect,
}) => {
	const [plyr, setPlyr] = useState<Plyr | null>(null);

	const videoRef = useRef<HTMLVideoElement>(null),
		onClick = useCallback(
			(e: MouseEvent<HTMLAnchorElement>) => {
				e.preventDefault();
				onSelect && onSelect(id);
			},
			[onSelect]
		);

	useEffect(() => {
		if (!videoRef.current) return;

		// Plyr only works client side so only require it when we need it
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const PlyrClass = require("plyr") as typeof Plyr;

		const plyr = new PlyrClass(videoRef.current, {
			ratio: "16:10",
			hideControls: true,
			displayDuration: false,
			controls: ["play-large", "play", "progress", "fullscreen"],
			muted: true,
		});

		plyr.on("ended", () => {
			if (onVideoEnded) onVideoEnded();
		});

		setPlyr(plyr);
	}, [videoRef, onVideoEnded]);

	useEffect(() => {
		if (!plyr) return;

		if (isPaused) plyr.pause();
		else if (isCurrent) plyr.play();
		else {
			plyr.pause();
			plyr.currentTime = 0;
		}
	}, [isCurrent, plyr, isPaused]);

	return (
		<>
			<Appear
				fromLeft={!textRight}
				fromRight={textRight}
				elementType="p"
				className={classNames(styles.text, {
					[styles.textCurrent]: isCurrent,
				})}
			>
				<a href={`#${id}`} onClick={onClick}>
					<ChevronRightIcon
						focusable="false"
						aria-hidden="true"
						className={styles.icon}
					/>
					{text}
				</a>
			</Appear>
			<div
				id={id}
				className={classNames(styles.media, {
					[styles.mediaLeft]: textRight,
					[styles.mediaCurrent]: isCurrent,
				})}
			>
				<Appear
					fromLeft={textRight}
					fromRight={!textRight}
					className={styles.videoWrapper}
				>
					<video src={videoPath} ref={videoRef} preload="none" controls muted>
						{image}
					</video>
				</Appear>
			</div>
		</>
	);
};
