import React, { ReactElement, FC } from "react";
import { graphql, PageProps } from "gatsby";
import { PartialShowcase } from "../types/types";
import { Layout } from "../components/Layout/Layout";
import { Hero } from "../components/Hero/Hero";
import { Brands } from "../components/Brands/Brands";
import { FullQuote } from "../components/FullQuote/FullQuote";
import { FeatureGrid } from "../components/FeatureGrid/FeatureGrid";
import { CardCarousel } from "../components/CardCarousel/CardCarousel";
import { ShowcaseCard } from "../components/Cards/ShowcaseCard";
import { SEO } from "../components/SEO/SEO";
import { Editor } from "../components/Editor/Editor";
import { Callout } from "../components/Callout/Callout";
import { Award } from "../components/Award/Award";

type HomePageProps = PageProps<{
	allPrismicShowcase: {
		nodes: PartialShowcase[];
	};
}>;

const IndexPage: FC<HomePageProps> = ({
	data: {
		allPrismicShowcase: { nodes: showcases },
	},
}): ReactElement => {
	return (
		<Layout>
			<SEO />

			<Hero />

			<Brands />

			<Editor />

			<FeatureGrid />

			<FullQuote citeName="Tanis M" citeCompany="Quadmark">
				“ We’ve seen a 400% behaviour uplift on completion of our
				training!&nbsp;”
			</FullQuote>

			<Award />

			<CardCarousel
				heading="Play the showcase"
				cta="See all games"
				ctaLink="/showcase/"
			>
				{showcases.map((item) => {
					return <ShowcaseCard key={item.id} content={item} />;
				})}
			</CardCarousel>

			<Callout />
		</Layout>
	);
};

export default IndexPage;
export const pageQuery = graphql`
	{
		allPrismicShowcase(
			limit: 3
			sort: {
				fields: [data___is_featured, first_publication_date]
				order: [ASC, DESC]
			}
		) {
			nodes {
				...PartialShowcase
			}
		}
	}
`;
