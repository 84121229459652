import React, { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";

import styles from "./Award.module.scss";
import { Container } from "../Container/Container";

export const Award: FC = () => (
	<section
		className={styles.wrapper}
		aria-label="Best eLearning authoring tools award 2020"
	>
		<Container>
			<StaticImage
				src="../../assets/award.png"
				alt=""
				className={styles.image}
			/>
			<p className={styles.text}>
				Awarded by eLearning Industry&nbsp;as
				<br />
				<q>one of the best eLearning authoring tools in&nbsp;2020</q>
			</p>
		</Container>
	</section>
);
