import React, { FC } from "react";

import InnovateLogo from "../../assets/brands/innovate.svg";
import GoogleLogo from "../../assets/brands/google.svg";
import QuadmarkLogo from "../../assets/brands/quadmark.svg";
import HeySummitLogo from "../../assets/brands/heysummit.svg";
import CabinetOfficeLogo from "../../assets/brands/cabinetoffice.svg";

import styles from "./Brands.module.scss";
import { PageSection } from "../PageSection/PageSection";
import { Appear } from "../Appear/Appear";

export const Brands: FC = () => (
	<PageSection id="brands" heading="Used by" headingType="subtle">
		<Appear
			elementType="ul"
			fromBelow
			aria-label="Brands using Adeptly"
			className={styles.list}
		>
			<li aria-label="Google">
				<GoogleLogo
					className={styles.logo}
					focusable="false"
					aria-hidden="true"
				/>
			</li>
			<li aria-label="Quadmark">
				<QuadmarkLogo
					className={styles.logo}
					focusable="false"
					aria-hidden="true"
				/>
			</li>
			<li aria-label="Innovate UK">
				<InnovateLogo
					className={styles.logo}
					focusable="false"
					aria-hidden="true"
				/>
			</li>
			<li aria-label="HeySummit">
				<HeySummitLogo
					className={styles.logo}
					focusable="false"
					aria-hidden="true"
				/>
			</li>
			<li aria-label="Cabinet Office">
				<CabinetOfficeLogo
					className={styles.logo}
					focusable="false"
					aria-hidden="true"
				/>
			</li>
		</Appear>
	</PageSection>
);
