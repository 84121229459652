import React, { FC, ReactNode } from "react";

import { Appear } from "../Appear/Appear";
import { Container } from "../Container/Container";

import styles from "./FullQuote.module.scss";

export interface FullQuoteProps {
	children: ReactNode;
	citeName: string;
	citeCompany: string;
	[prop: string]: unknown;
}

export const FullQuote: FC<FullQuoteProps> = ({
	children,
	citeName,
	citeCompany,
	...attrs
}) => (
	<div className={styles.wrapper} {...attrs}>
		<Container>
			<blockquote className={styles.quote}>
				<Appear>{children}</Appear>
				<Appear>
					<footer className={styles.footer}>
						<cite className={styles.cite}>
							<span className={styles.citeName}>{citeName}</span>{" "}
							<span className={styles.citeCompany}>{citeCompany}</span>
						</cite>
					</footer>
				</Appear>
			</blockquote>
		</Container>
	</div>
);
