import React, { FC, useCallback, useEffect, useState } from "react";

import { Container } from "../Container/Container";
import { Button } from "../Button/Button";

import DesktopHeroImage from "../../assets/hero-desktop.svg";
import MobileHeroImage from "../../assets/hero-mobile.svg";

import styles from "./Hero.module.scss";

const usages = [
	"training",
	"education",
	"marketing",
	"events",
	"assessment",
] as const;

const typingPause = 75,
	fullPause = 2000,
	emptyPause = 1000;

interface HeroState {
	usageIndex: number;
	text: string;
	decreasing: boolean;
	timeout: ReturnType<typeof setTimeout> | null;
}

export const Hero: FC = () => {
	const [current, setCurrent] = useState<HeroState>({
		usageIndex: 0,
		text: usages[0],
		decreasing: true,
		timeout: null,
	});

	const looper = useCallback(() => {
		setCurrent((current) => {
			const { usageIndex, text, decreasing } = current,
				newIndex =
					text !== ""
						? usageIndex
						: usageIndex === usages.length - 1
						? 0
						: usageIndex + 1,
				fullText = usages[newIndex],
				newDecreasing =
					text === "" ? false : text === fullText ? true : decreasing,
				newLength = newDecreasing ? text.length - 1 : text.length + 1;

			const timeout = setTimeout(
				looper,
				newLength == 0
					? emptyPause
					: newLength === fullText.length
					? fullPause
					: typingPause
			);

			return {
				usageIndex: newIndex,
				decreasing: newDecreasing,
				text: fullText.substring(0, newLength),
				timeout,
			};
		});
	}, [current]);

	// Kick off the initial animation
	useEffect(() => {
		const timeout = setTimeout(looper, fullPause);
		setCurrent((current) => ({
			...current,
			timeout,
		}));
	}, []);

	// Separate effect to remove the timeout on unmount
	useEffect(() => {
		return () => {
			const { timeout } = current;
			if (timeout) clearTimeout(timeout);
		};
	}, [current]);

	return (
		<div className={styles.wrapper}>
			<Container className={styles.container}>
				<div className={styles.text}>
					<h1 className={styles.heading1}>
						Build games{" "}
						<span className={styles.heading1LastWords}>
							for&nbsp;
							<span
								className={styles.usageWrapper}
								style={{ "--text": `"${current.text}"` } as React.CSSProperties}
							>
								{usages.map((usage, i) => (
									<span className={styles.usage} key={usage}>
										{i === 0 ? null : (
											<>{i === usages.length - 1 ? " or " : ", "}</>
										)}
										{usage}
									</span>
								))}
							</span>
						</span>
					</h1>
					<p className={styles.lead}>
						The award winning online game builder that transforms your narrative
						into an adaptive learning&nbsp;game.
					</p>
				</div>

				<div className={styles.image}>
					<MobileHeroImage
						className={styles.mobileImage}
						focusable={false}
						aria-hidden="true"
					/>
					<DesktopHeroImage
						className={styles.desktopImage}
						focusable={false}
						aria-hidden="true"
					/>
				</div>

				<div className={styles.actions}>
					<Button to="https://my.adeptly.ai/" className={styles.cta}>
						Start building
					</Button>
				</div>
			</Container>
		</div>
	);
};
